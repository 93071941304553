.app-topbar-group {
    min-width: 20vw;
    border-right: 1px solid #ccc;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    height: 100%;
    font-size: 1rem;
}
.app-topbar {
    border-bottom: 1px solid var(--gray-light);
    -webkit-box-shadow: 0px -2px 14px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -2px 14px 0px rgba(0,0,0,0.75);
    box-shadow: 0px -2px 14px 0px rgba(0,0,0,0.75);
}


.app-topbar-group .app-topbar-group-children {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
}

.app-topbar-group .app-topbar-item-icon {
    display: block;
    height: 1.8em;
    width: 1.8em;
    padding: 0.2rem;
    margin: 0.2rem;
    border: 1px solid  #ccc;
    border-radius: .15rem;
    text-align: center;
}

.app-topbar-group-title {
    text-align: center;
}

.app-topbar-item-icon svg {
    vertical-align: inherit;
}