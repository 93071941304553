:root {
  /** primary **/
  --color-primary: #545cd8;
  --color-primary-rgb: 84, 92, 216;
  --color-primary-light: #878de4;
  --color-primary-shade: #3b4097;

  /** success **/
  --color-success: #10dc60;
  --color-success-rgb: 16, 220, 96;
  --color-success-shade: #0ec254;
  --color-success-light: #28e070;

  /** warning **/
  --color-warning: #ffce00;
  --color-warning-rgb: 255, 206, 0;
  --color-warning-alpha: rgba(var(--color-warning-rgb), 0.5);
  --color-warning-shade: #e0b500;
  --color-warning-light: #ffd31a;

  /** danger **/
  --color-danger: #f04141;
  --color-danger-rgb: 245, 65, 65;
  --color-danger-alpha: rgba(var(--color-danger-rgb), 0.5);
  --color-danger-shade: #a82e2e;
  --color-danger-light: #f57a7a;

  /** dark **/
  --color-dark: #222428;
  --color-dark-rgb: 34, 34, 34;
  --color-dark-shade: #1e2023;
  --color-dark-light: #383a3e;

  /** gray **/
  --color-gray: #f1f4f6;
  --color-gray-rgb: 152, 154, 162;
  --color-gray-shade: #c1c3c5;
  --color-gray-light: #f9fbfb;
  --color-gray-dark: #303131;
}

body {
  background-color: var(--color-gray);
  font-family: Roboto, "Open Sans", sans-serif;
  color: var(--color-gray-dark);
  font-size: 1rem !important;
  overflow-y: hidden;
}

#root {
  height: 100vh;
  display: flex;
  flex-flow: column;
}

a {
  color: #3c4043;
  transition: all 0.3s;
}

a:hover {
  color: #545cd8;
  background: #e0f3ff;
}

.app-topbar {
  height: 70px;
}

.footer {
  position: absolute;
  bottom: 0;
}

button {
  outline: none !important;
}
span.small {
  opacity: 0.8 !important;
  font-size: 85%;
  font-weight: 400;
}

.pb-6 {
  padding-bottom: 6rem !important;
}
.mt {
  margin-top: 30px;
}
.success {
  color: var(--color-success, #0f0);
}
.danger {
  color: var(--color-danger, #f00);
}

.shadowed {
  -webkit-box-shadow: 4px 4px 10px 0px rgba(var(--color-gray-rgb), 0.5);
  -moz-box-shadow: 4px 4px 10px 0px rgba(var(--color-gray-rgb), 0.5);
  box-shadow: 4px 4px 10px 0px rgba(var(--color-gray-rgb), 0.5);
  border: 0.7px solid var(--color-gray-shade);
  border-radius: 0.25rem;
  background-color: #fff;
}

.app-loading-spinner {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
}
.clearfix {
  display: flex;
  flex-wrap: wrap;
}

.panel-field.col-auto {
  padding: 0px 10px;
  margin-top: 12px;
}
.rbc-date-cell > a {
  pointer-events: none;
}
.rbc-date-cell {
  cursor: pointer;
}
.color-primary {
  color: var(--color-primary);
}
.select__multi-value__label {
  cursor: pointer;
}
.select__multi-value__label:hover {
  border: 1px solid var(--color-primary);
}

/*
DIALOG WIDTHs
*/
.MuiDialog-paperWidthSm {
  width: 50vw;
}

@media(max-width: 1200px) {
  body, .MuiInputBase-input, .MuiTypography-body1 {
    font-size: 0.8rem !important;
  }

  .MuiSvgIcon-root {
    font-size: 1rem !important;
  }

  .app-main {
    overflow: auto;
  }

  .app-table-button-icon svg {
    width: 18px!important;
    height: 18px!important;
  }
}